import React, { useState } from 'react';


import { directionFlags, flags } from 'services/maze';

const cn = require('./Tile-cn.css');

interface Props {
  cell: number;
  disableRotation: boolean;
  onTouch: () => void;
  onRotationEnd: () => void;
}

const mapCell = (cell: number) => {
  return {
    top: !!(cell & flags.top),
    right: !!(cell & flags.right),
    bottom: !!(cell & flags.bottom),
    left: !!(cell & flags.left),
    start: !!(cell & flags.start),
    visited: !!(cell & flags.visited),
  };
};

const Tile = ({
  cell,
  disableRotation,
  onTouch: onClick,
  onRotationEnd,
}: Props) => {
  const [rotating, setRotating] = useState(false);
  const { top, right, bottom, left, start, visited } = mapCell(cell);

  const end =
    (cell & directionFlags) === flags.top ||
    (cell & directionFlags) === flags.right ||
    (cell & directionFlags) === flags.bottom ||
    (cell & directionFlags) === flags.left;

  const handleTouch = () => {
    if (rotating || disableRotation) return;
    onClick();
    setRotating(true);
  };

  const handleTransitionEnd = () => {
    onRotationEnd();
    setRotating(false);
  };

  return (
    <div
      className={[cn.cell, visited && cn.visited, rotating && cn.rotating]
        .filter(Boolean)
        .join(' ')}
      onTouchStart={handleTouch}
      onTouchEnd={e => e.preventDefault()}
      onMouseDown={handleTouch}
      onTransitionEnd={handleTransitionEnd}
    >
      {start && <div className={[cn.circle, cn.start].join(' ')} />}
      {end && <div className={[cn.circle, cn.end].join(' ')} />}
      {top && <div className={[cn.path, cn.pathTop].join(' ')} />}
      {right && <div className={[cn.path, cn.pathRight].join(' ')} />}
      {bottom && <div className={[cn.path, cn.pathBottom].join(' ')} />}
      {left && <div className={[cn.path, cn.pathLeft].join(' ')} />}
    </div>
  );
};

export default Tile;

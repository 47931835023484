import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';


const cn = require('./ButtonLink-cn.css');

type Props = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const ButtonLink = ({ className, children, ...rest }: Props) => {
  return (
    <a {...rest} className={[className, cn.link].join(' ')}>
      {children}
    </a>
  );
};

export default ButtonLink;

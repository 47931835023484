import React from 'react';


import { formatTime } from 'services/format';

const cn = require('./GameScore-cn.css');

interface Props {
  time: number;
  taps: number;
}

const GameScore = ({ time, taps }: Props) => {
  return (
    <div className={cn.score}>
      <div>
        <div className={cn.label}>Time</div>
        <div className={cn.value}>{formatTime(time)}</div>
      </div>
      <div>
        <div className={cn.label}>Taps</div>
        <div className={cn.value}>{taps}</div>
      </div>
    </div>
  );
};

export default GameScore;

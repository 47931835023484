import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';


const cn = require('./Button-cn.css');

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = ({ className, ...rest }: Props) => {
  return <button {...rest} className={[className, cn.button].join(' ')} />;
};

export default Button;

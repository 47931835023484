import React from 'react';
import { Link } from 'react-router-dom';


import { formatTime } from 'services/format';
import { Score, useGlobalScore } from 'services/score';

const cn = require('./Level-cn.css');

interface ScoreValuesProps {
  title: string;
  score?: Score;
}

const ScoreValues = ({ title, score }: ScoreValuesProps) => {
  return (
    <div className={cn.item}>
      <div className={cn.label}>{title}</div>
      {score ? (
        <>
          <div className={cn.value}>{formatTime(score.time)}</div>
          <div className={cn.value}>{score.taps} taps</div>
        </>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

interface Props {
  title: string;
  cells?: number;
  rows: number;
  cols: number;
}

const Level = ({ title, rows, cols }: Props) => {
  const { score } = useGlobalScore();

  const level = `${rows}x${cols}`;
  const { best, last } = score[level] || {};

  return (
    <Link to={`/game/${rows}-${cols}`} className={cn.level}>
      <h2>{title}</h2>

      <div className={cn.score}>
        <ScoreValues title="Best" score={best} />
        <ScoreValues title="Last" score={last} />
      </div>
    </Link>
  );
};

export default Level;

import React from 'react';


const cn = require('./Header-cn.css');

interface Props {
  children: React.ReactNode;
}

const Header = ({ children }: Props) => {
  return <div className={cn.header}>{children}</div>;
};

export default Header;

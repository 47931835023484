import React from 'react';


const cn = require('./Container-cn.css');

interface Props {
  children: React.ReactNode;
}

const index = ({ children }: Props) => {
  return (
    <div className={cn.container}>
      <div className={cn.content}>{children}</div>
    </div>
  );
};

export default index;
